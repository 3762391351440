import React from "react"
import VideoFile from "./../../../images/startvideo/video.mp4"
import styles from "./video.module.scss"

function Video() {
  return (
    <div className={styles.videoContainer}>
      <video autoPlay="autoplay" loop="loop" muted preload="yes" playsInline>
        <source src={VideoFile} type="video/mp4" />
      </video>
    </div>
  )
}

export default Video
