import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Main from "../components/partials/main"
import honeycomb from "./../images/start/waben-start.png"
import { connect } from "react-redux"
import { CookiesProvider } from "react-cookie"
import Provisorium from "./../images/start/automat_still3.jpg"
import ProvisoriumLarge from "./../images/start/automat_still3@2x.jpg"
import { Image } from "../components/ux/image"
import Video from "../components/ux/customvideo/video"

const IndexPage = ({ translations, lang, showPromo }) => {
  let isBrowser = typeof window !== "undefined"
  let currentWidth = 0
  if (isBrowser) {
    currentWidth = window.innerWidth
  }

  let bgClass = ''
  if(showPromo === false || lang === 'en'){
    bgClass = 'bg-centered'
  }

  return (
    <CookiesProvider>
      <Layout cutted={true}>
        <SEO
          title={translations.home_title}
          description={translations.home_meta_description}
        />
        <section className="startsite">
          <div className="lightholder"></div>
          <div className="hero">
            {currentWidth > 750 &&
              (process.env.EMBED_YOUTUBE === "true" ? (
                <img
                  src={honeycomb}
                  className="hero-bg break5"
                  alt="Videobackground"
                />
              ) : (
                <Image
                  img={Provisorium}
                  img2={ProvisoriumLarge}
                  styles={"hero-bg break5 interims"}
                  alt="Videobackground"
                />
              ))}
            {currentWidth > 750 && process.env.EMBED_YOUTUBE === "true" && (
              <Video />
            )}
          </div>
          <div className={`inner-content ${bgClass}`}>
            <div className="claim">
              <div className="flex sb">
                <Main></Main>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </CookiesProvider>
  )
}

const mapStateToProps = state => ({
  translations: state.preferences.translations,
  lang: state.preferences.lang,
  showPromo: state.preferences.showPromo,
})

export default connect(mapStateToProps)(IndexPage)
