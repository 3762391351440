import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import stoerer from "../../images/start/promo-hexagon-neutral.png"
import GhostButton from "../ux/buttons/ghost-button"
import { nl2br } from "../../utils/functions"

function Main({ translations, lang, showPromo }) {
  const [spacerClass, setSpacerClass] = useState("")

  useEffect(() => {
    if (lang === "en" || showPromo === false) {
      setSpacerClass(" fill-empty-space")
    }
  }, [lang, showPromo])


  /* eslint-disable react/jsx-no-target-blank */

  return (
    <>
      <div className="promo-part mobile">
        {lang === "de" && showPromo === true && (
          <>
            <Link to="/contact-us" target="_blank">
              {
                <img src={stoerer} alt="Promoimage" style={{ width: '100%', objectFit: 'cover' }} className="safariAnimation" />
              }
            </Link>
            <Link
              to="/contact-us"
              style={{ zIndex: 50 }}
            >
              <GhostButton
                title={translations.more_info}
                className="ghostbtn"
              />
            </Link>
          </>
        )}
      </div>
      <div className={`claim-holder${spacerClass}`}>
        <h1>
          {translations.der}
          <br />
          <span className="yellow">{translations.proteinshake}</span>
          <br />
          <span className="yellow">{translations.machine}.</span>
        </h1>
        <p className="intro mt">{nl2br(translations.startsite_claim)}</p>
        <p className="light">{translations.startsite_desc}</p>
        <GhostButton title={translations.more_info} target={lang === 'de' ? '/machines/' : '/en/machines/'} className="ghostbtn" />
        {/* {lang === "de" && showPromo === true && (
          <div style={{ position: "absolute", bottom: 0 }}>
            <span
              className="halyard light"
              style={{ fontSize: 16, color: "#bfbfbf" }}
            >
              * nur gültig in Kombination mit der Überbrückungshilfe III
            </span>
          </div>
        )} */}
      </div>
      <div className="promo-part mobile-exclude">
        {lang === "de" && showPromo === true && (
          <div className="flex right mobile-exclude">
            <div
              className="flex middle column text-center"
              style={{ padding: "30px 20px 0 0" }}
            >
              <Link to="/contact-us">
                {
                  <div className="pb">
                    <img src={stoerer} alt="Promoimage" style={{ width: '80%', objectFit: 'cover' }} className="safariAnimation" />
                  </div>
                }
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  translations: state.preferences.translations,
  lang: state.preferences.lang,
  showPromo: state.preferences.showPromo,
})

export default connect(mapStateToProps)(Main)
